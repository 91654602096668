<template>
  <b-card
      class="contract-edit-wrapper"
  >
    <ValidationObserver ref="observer">
    <!-- form -->
    <b-form id="contractForm" class="edit-form mt-2">
      <b-row>
        <b-col md="12">
          <div class="inner-card-title">基本信息</div>
        </b-col>

<!--        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="是否是VMI"
              label-for="agreement_is_changed"
              label-size="sm"
              class="mb-1"
          >
            <b-form-checkbox
                name="check-button"
                switch
                inline
                @change="isVMI($event)"
                v-model="isRequired"
                value="1"
                unchecked-value="0"
            ></b-form-checkbox>
          </b-form-group>
        </b-col>-->

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="标题"
              label-for="contract_title"
              label-size="sm"
              :class="'mb-1 '+ required"
          >
            <b-form-input
                id="contract_title"
                size="sm"
                v-model="contract.contract_title"
            />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="申请时间"
              label-for="applier_date"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="applier_date"
                size="sm"
                v-model="contract.applier_date"
                readonly=""
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="流程编号"
              label-for="contract_no"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="contract_no"
                size="sm"
                v-model="contract.contract_no"
                readonly=""
            />
          </b-form-group>
        </b-col>
        <!--        <b-col md="3">-->
        <!--          <b-form-group-->
        <!--              label-cols="3"-->
        <!--              label-cols-lg="3"-->
        <!--              label="申请人ID user表"-->
        <!--              label-for="applier_id"-->
        <!--              label-size="sm"-->
        <!--              class="mb-1"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--                id="applier_id"-->
        <!--                size="sm"-->
        <!--                v-model="contract.applier_id"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="申请人"
              label-for="applier_name"
              label-size="sm"
              :class="'mb-1 '+ required"
          >
            <b-form-input
                id="applier_name"
                size="sm"
                v-model="username"
                v-b-modal.modal-select-user
                placeholder="点击搜索申请人"
                readonly=""
            />
          </b-form-group>
        </b-col>
        <b-modal
            id="modal-select-user"
            ok-only
            ok-title="确认"
            @ok="onSelectProducts"
            cancel-title="取消"
            centered
            size="lg"
            title="选择姓名"
        >
          <user-select
              ref="myUserSelect" :department-id="departmentId" v-on:table="fromChildren($event,'modal-select-user')"
          >
          </user-select>
        </b-modal>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="申请部门"
              label-for="applier_department_name"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="applier_department_name"
                size="sm"
                v-model="contract.applier_department_name"
                readonly=""
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="所属公司"
              label-for="applier_company_name"
              label-size="sm"
              class="mb-1"
          >
            <b-form-input
                id="applier_company_name"
                size="sm"
                v-model="contract.applier_company_name"
                readonly=""
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <div class="inner-card-title">合同信息</div>
        </b-col>

        <b-col md="12">
          <b-card header="合同信息">
            <b-row>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="合同类型"
                    label-for="agreement_type"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <v-select
                      id="agreement_type"
                      :options="type==1?getCodeOptions('agreement_specific_type_CG'):getCodeOptions('agreement_specific_type_XS')"
                      :clearable="true"
                      v-model="agreementType"
                      @input="changeSelect('agreement_type',$event)"
                      class="select-size-sm"
                      placeholder="请选择合同类型"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="合同版本"
                    label-for="agreement_batch"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <v-select
                      id="agreement_batch"
                      :options="getCodeOptions('agreement_batch')"
                      :clearable="true"
                      v-model="agreementBatch"
                      @input="changeSelect('agreement_batch',$event)"
                      class="select-size-sm"
                      placeholder="请选择合同版本"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="是否有修改"
                    label-for="agreement_is_changed"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      id="agreement_is_changed"
                      v-model="contract.agreement_is_changed"
                      value="1"
                      unchecked-value="0"
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="合同名称"
                    label-for="agreement_name"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="agreement_name"
                      size="sm"
                      v-model="contract.agreement_name"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="合同编号"
                    label-for="agreement_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="agreement_no"
                      size="sm"
                      v-model="contract.agreement_no"
                      readonly=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="公章类型"
                    label-for="seal_type"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <v-select
                      id="seal_type"
                      :options="getCodeOptions('seal_type')"
                      :clearable="true"
                      v-model="sealType"
                      @input="changeSelect('seal_type',$event)"
                      class="select-size-sm"
                      placeholder="请选择公章类型"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="合同份数"
                    label-for="agreement_count"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="agreement_count"
                      size="sm"
                      v-model="contract.agreement_count"
                  />
                </b-form-group>
              </b-col>
              <!--              <b-col md="4">-->
              <!--                <b-form-group-->
              <!--                    label-cols="3"-->
              <!--                    label-cols-lg="3"-->
              <!--                    label="供方来源"-->
              <!--                    label-for="provide_source"-->
              <!--                    label-size="sm"-->
              <!--                    class="mb-1"-->
              <!--                >-->
              <!--                  <v-select-->
              <!--                      id="provide_source"-->
              <!--                      :options="getCodeOptions('provide_source')"-->
              <!--                      :clearable="true"-->
              <!--                      v-model="provideSource"-->
              <!--                      @input="changeSelect('provide_source',$event)"-->
              <!--                      class="select-size-sm"-->
              <!--                  />-->
              <!--                </b-form-group>-->
              <!--              </b-col>-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="合同开始时间"
                    label-for="agreement_start"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <flat-pickr
                      v-model="contract.agreement_start"
                      class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="合同结束时间"
                    label-for="agreement_end"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <flat-pickr
                      v-model="contract.agreement_end"
                      class="form-control"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="适用哪国法律"
                    label-for="reference_law"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="reference_law"
                      size="sm"
                      v-model="contract.reference_law"
                      readonly=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="争议解决方式"
                    label-for="different_idea_method"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <v-select
                      id="different_idea_method"
                      :options="getCodeOptions('different_idea_method')"
                      :clearable="true"
                      v-model="differentIdeaMethod"
                      @input="changeSelect('different_idea_method',$event)"
                      class="select-size-sm"
                      placeholder="请选择争议解决方式"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仲裁地点"
                    label-for="arbitrate"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <v-select
                      id="arbitrate"
                      :options="getCodeOptions('arbitrate_locale')"
                      :clearable="true"
                      v-model="arbitrate"
                      @input="changeSelect('arbitrate',$event)"
                      class="select-size-sm"
                      placeholder="请选择仲裁地点"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="仲裁地点"
                    label-for="arbitrate_other"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="arbitrate_other"
                      size="sm"
                      v-model="contract.arbitrate_other"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="原件类型"
                    label-for="sourcefile_type"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="sourcefile_type"
                      :options="getCodeOptions('sourcefile_type')"
                      :clearable="true"
                      v-model="sourcefileType"
                      @input="changeSelect('sourcefile_type',$event)"
                      class="select-size-sm"
                      placeholder="请选择原件类型"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="其他"
                    label-for="other_info"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="other_info"
                      size="sm"
                      v-model="contract.other_info"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="合同扫描件"
                    label-for="attachments_sourcefile"
                    label-size="sm"
                    :class="'mb-1 hidden  '+ required "
                >
                  <attachment-upload v-if="contract.loaded" :theme="'files'"
                                     :attachment_id="'attachments_sourcefile'"
                                     :id="contract.attachments_sourcefile"
                                     :object_type="'contract'"
                                     :object_id="contract.contract_id"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>

        </b-col>

        <b-col md="12">
          <b-card header="双方信息">

            <b-row>
              <!--              <b-col md="4">-->
              <!--                <b-form-group-->
              <!--                    label-cols="3"-->
              <!--                    label-cols-lg="3"-->
              <!--                    label="我方单位ID  company表中company_type为2的公司"-->
              <!--                    label-for="our_company_id"-->
              <!--                    label-size="sm"-->
              <!--                    class="mb-1"-->
              <!--                >-->
              <!--                  <b-form-input-->
              <!--                      id="our_company_id"-->
              <!--                      size="sm"-->
              <!--                      v-model="ourCompanyId"-->
              <!--                  />-->
              <!--                </b-form-group>-->
              <!--              </b-col>-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="我方单位"
                    label-for="our_company_name"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <!--   <v-select
                         id="our_company_name"
                         :options="companys"
                         :clearable="true"
                         class="select-size-sm"
                         v-model="ourCompanyName"
                         @input="clearInfo($event), setOurCompanyName"
                         readonly=""
                         placeholder="请选择我方单位名称"
                     />  -->
                  <b-form-input
                      id="our_company_name"
                      v-model = "ourCompanyName"
                      @click="changeOurCompanyName()"
                      readonly
                      placeholder="请选择我方单位名称"
                  />
                </b-form-group>
              </b-col>
              <b-modal
                  id="modal-select-our"
                  ok-title="确认"
                  @ok="onSelectOurName"
                  @cancel="closeOurModal"
                  cancel-title="取消"
                  centered
                  size="lg"
                  no-close-on-backdrop
                  hide-header-close
                  title="选我方择对象单位"
                  :visible="loadingOurModal"
              >
                <company-select
                    :companyType="companyType"
                    ref="CompanySelect"
                    v-on:table="fromChildren($event,'modal-select-our')"
                >
                </company-select>

              </b-modal>
              <!--              <b-col md="4">-->
              <!--                <b-form-group-->
              <!--                    label-cols="3"-->
              <!--                    label-cols-lg="3"-->
              <!--                    label="我方负责人ID    user表"-->
              <!--                    label-for="our_manage_id"-->
              <!--                    label-size="sm"-->
              <!--                    class="mb-1"-->
              <!--                >-->
              <!--                  <b-form-input-->
              <!--                      id="our_manage_id"-->
              <!--                      size="sm"-->
              <!--                      v-model="contract.our_manage_id"-->
              <!--                  />-->
              <!--                </b-form-group>-->
              <!--              </b-col>-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="我方负责人"
                    label-for="our_manage_name"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="our_manage_name"
                      size="sm"
                      v-model="contract.our_manage_name"
                      @input="ourManageName"
                      v-b-modal.modal-select-users
                      placeholder="点击搜索我方负责人"
                      readonly=""
                  />
                </b-form-group>
              </b-col>

              <b-modal
                  id="modal-select-users"
                  ok-only
                  ok-title="确认"
                  @ok="onSelectProductsOnUser"
                  cancel-title="取消"
                  centered
                  size="lg"
                  title="选择姓名"
              >
                <user-select
                    ref="myUserSelects"
                    :department-id="departmentId"
                    v-on:table="fromChildren($event,'modal-select-users')"
                >
                </user-select>
              </b-modal>

              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="我方联系人"
                    label-for="our_manage_tel"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="our_manage_tel"
                      size="sm"
                      v-model="contract.our_manage_tel"
                      readonly=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="我方邮箱"
                    label-for="our_manage_email"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="our_manage_email"
                      size="sm"
                      v-model="contract.our_manage_email"
                      readonly=""
                  />
                </b-form-group>
              </b-col>
              <!--              <b-col md="4">-->
              <!--                <b-form-group-->
              <!--                    label-cols="3"-->
              <!--                    label-cols-lg="3"-->
              <!--                    label="对方单位ID"-->
              <!--                    label-for="other_company_id"-->
              <!--                    label-size="sm"-->
              <!--                    class="mb-1"-->
              <!--                >-->
              <!--                  <b-form-input-->
              <!--                      id="other_company_id"-->
              <!--                      size="sm"-->
              <!--                      v-model="otherCompanyId"-->
              <!--                  />-->
              <!--                </b-form-group>-->
              <!--              </b-col>-->
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="对方单位"
                    label-for="other_company_name"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <!--   <v-select
                         id="other_company_name"
                         :options="otherCompanys"
                         v-model="otherCompanyName"
                         :clearable="true"
                         class="select-size-sm"
                         @input="clearInfoOtherCompany($event), setOtherCompanyName"
                         placeholder="请选择对方单位名称"
                     />-->

                  <b-form-input
                      id="other_company_name"
                      v-model = "otherCompanyName"
                      @click="changeOtherCompanyName()"
                      readonly
                      placeholder="点击选择对方单位"
                  />
                </b-form-group>
              </b-col>
              <b-modal
                  id="modal-select-other"
                  ok-title="确认"
                  @ok="onSelectOtherName"
                  @cancel="closeOtherModal"
                  cancel-title="取消"
                  centered
                  size="lg"
                  no-close-on-backdrop
                  hide-header-close
                  title="选择对方单位"
                  :visible="loadingOtherModal"
              >
                <company-select
                    :companyType="companyType"
                    ref="CompanySelect"
                    v-on:table="fromChildren($event,'modal-select-other')"
                >
                </company-select>

              </b-modal>
              <!--              <b-col md="4">-->
              <!--                <b-form-group-->
              <!--                    label-cols="3"-->
              <!--                    label-cols-lg="3"-->
              <!--                    label="对方负责人ID"-->
              <!--                    label-for="other_contact_id"-->
              <!--                    label-size="sm"-->
              <!--                    class="mb-1"-->
              <!--                >-->
              <!--                  <b-form-input-->
              <!--                      id="other_contact_id"-->
              <!--                      size="sm"-->
              <!--                      v-model="contract.other_contact_id"-->
              <!--                      readonly=""-->
              <!--                  />-->
              <!--                </b-form-group>-->
              <!--              </b-col>-->

              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="对方负责人"
                    label-for="other_contact_name"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="other_contact_name"
                      size="sm"
                      v-model="contract.other_contact_name"
                      @input="otherContactName"
                      v-b-modal.modal-select-contact
                      placeholder="点击搜索对方负责人"
                      readonly=""
                  />
                </b-form-group>
              </b-col>

              <b-modal
                  id="modal-select-contact"
                  ok-only
                  ok-title="确认"
                  @ok="onSelectContact"
                  cancel-title="取消"
                  centered
                  size="lg"
                  title="选择姓名"
              >
                <contact-select
                    ref="myContactSelects" :companyId="companyIdOtherRelational"
                    v-on:table="fromChildren($event,'modal-select-contact')"
                >
                </contact-select>
              </b-modal>

              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="对方联系人"
                    label-for="other_contact_tel"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="other_contact_tel"
                      size="sm"
                      v-model="contract.other_contact_tel"
                      readonly=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="对方邮箱"
                    label-for="other_contact_email"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="other_contact_email"
                      size="sm"
                      v-model="contract.other_contact_email"
                      readonly=""
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="12">
          <b-card header="风控">
            <b-row>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="企业实控人"
                    label-for="actual_controller"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      id="actual_controller"
                      size="sm"
                      v-model="contract.actual_controller"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="实控人身份证号"
                    label-for="actual_card"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      id="actual_card"
                      size="sm"
                      v-model="contract.actual_card"
                      readonly
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="实控人手机号"
                    label-for="actual_phone"
                    label-size="sm"
                    class="mb-1 required"
                >
                  <b-form-input
                      id="actual_phone"
                      size="sm"
                      v-model="contract.actual_phone"
                      readonly
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">交货地点</div>
        </b-col>
        <b-col md="4">
          <b-form-group
              label-cols="3"
              label-cols-lg="3"
              label="交货地点"
              label-for="getgoods"
              label-size="sm"
              :class="'mb-1 '+ required"
          >
            <v-select
                id="getgoods"
                label="label"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                multiple
                :options="getCodeOptions('getgoods')"
                :clearable="true"
                v-model="getgoods"
                @input="changeSelect('getgoods',$event)"
                class="select-size-sm"
                placeholder="请选择交货地点"
            />
          </b-form-group>
        </b-col>

        <!--        <b-col md="4" v-if="contract.getgoods&&contract.getgoods.length>=7">-->
        <!--          <b-form-group-->
        <!--              label-cols="3"-->
        <!--              label-cols-lg="3"-->
        <!--              label="其他交货地点"-->
        <!--              label-for="getgoods_other"-->
        <!--              label-size="sm"-->
        <!--              class="mb-1"-->
        <!--          >-->
        <!--            <b-form-input-->
        <!--                size="sm"-->
        <!--                v-model="contract.getgoods_other"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <b-col md="12">
          <div class="inner-card-title">结算条款</div>
        </b-col>
        <b-col md="12">
          <b-card header="结算条款">
            <b-row>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="资金流向"
                    label-for="money_flow"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <v-select
                      id="money_flow"
                      :options="type==1?getCodeOptions('supplier_money_flow_CG'):getCodeOptions('supplier_money_flow_XS')"
                      :clearable="true"
                      v-model="moneyFlow"
                      @input="changeSelect('money_flow',$event)"
                      class="select-size-sm"
                      placeholder="请选择资金流向"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    :label="type==1?'提货方式':'发货方式'"
                    label-for="pick_method"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <v-select
                      :options="type==1?getCodeOptions('pickup_method_CG'):getCodeOptions('pickup_method_XS')"
                      :clearable="true"
                      v-model="pickMethod"
                      @input="changeSelect('pick_method',$event)"
                      class="select-size-sm"
                      placeholder="请选择方式"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    :label="type==1?'提货方式合同页码':'发货方式合同页码'"
                    label-for="pick_method_page"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="pick_method_page"
                      size="sm"
                      v-model="contract.pick_method_page"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="天数"
                    label-for="agreement_pay_days"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="agreement_pay_days"
                      size="sm"
                      v-model="contract.agreement_pay_days"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    :label="type==1?'收款方式':'付款方式'"
                    label-for="agreement_pay_method"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <v-select
                      id="agreement_pay_method"
                      :options="getCodeOptions('agreement_pay_method')"
                      :clearable="true"
                      v-model="agreementPayMethod"
                      @input="changeSelect('agreement_pay_method',$event)"
                      class="select-size-sm"
                      placeholder="请选择方式"
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="保证金"
                    label-for="deposit"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="deposit"
                      size="sm"
                      v-model="contract.deposit"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="定金"
                    label-for="bargain"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="bargain"
                      size="sm"
                      v-model="contract.bargain"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="账期描述"
                    label-for="payment_info"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="payment_info"
                      size="sm"
                      v-model="contract.payment_info"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="账期天数"
                    label-for="paymentdays"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <ValidationProvider rules="required|nonnegativeInteger" name="账期天数" #default="{ errors }">
                  <b-form-input
                      id="paymentdays"
                      size="sm"
                      v-model="contract.paymentdays"
                  />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="结算条款合同页码"
                    label-for="balance_page"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="balance_page"
                      size="sm"
                      v-model="contract.balance_page"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="结算币"
                    label-for="balance_currency"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="balance_currency"
                      size="sm"
                      v-model="contract.balance_currency"
                      readonly=""
                  />
                </b-form-group>
              </b-col>

              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    :label="type==1?'其他费用收款描述':'其他费用付款描述'"
                    label-for="otherfees"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="otherfees"
                      size="sm"
                      v-model="contract.otherfees"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="4"
                    label-cols-lg="4"
                    label="逾期违约金比率(%/日)"
                    label-for="break_promise_percent"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <ValidationProvider rules="required|nonnegative" name="逾期违约金比率(%/日)" #default="{ errors }">
                    <b-form-input
                        id="break_promise_percent"
                        size="sm"
                        v-model="contract.break_promise_percent"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    :label="type==1?'我方收取发票类型':'我方开立发票类型'"
                    label-for="bill_type"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <v-select
                      id="bill_type"
                      :options="getCodeOptions('invoice_type')"
                      :clearable="true"
                      v-model="billType"
                      @input="changeSelect('bill_type',$event)"
                      class="select-size-sm"
                      placeholder="请选择发票类型"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="税率"
                    label-for="tax_rate"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <v-select
                      id="tax_rate"
                      :options="getCodeOptions('tax_rate')"
                      :clearable="true"
                      v-model="taxRate"
                      @input="changeSelect('tax_rate',$event)"
                      class="select-size-sm"
                      placeholder="请选择税率"
                  />
                </b-form-group>
              </b-col>
<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    :label="type==1?'预付款':'预收款'"-->
<!--                    label-for="is_prepay"-->
<!--                    label-size="sm"-->
<!--                    :class="'mb-1 '+ required"-->
<!--                >-->
<!--                  <b-form-input-->
<!--                      id="prepay"-->
<!--                      size="sm"-->
<!--                      v-model="contract.prepay"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="发票和税率合同页码"
                    label-for="bill_fax_page"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="bill_fax_page"
                      size="sm"
                      v-model="contract.bill_fax_page"
                  />
                </b-form-group>
              </b-col>
<!--              <b-col md="4"></b-col>-->
<!--              <b-col md="4">-->
<!--                <b-row>-->
<!--                  <b-col md="6">-->
<!--                    <b-form-group-->
<!--                        label-cols="5"-->
<!--                        label-cols-lg="5"-->
<!--                        label="首款比例/%"-->
<!--                        label-for="first_pay_percent"-->
<!--                        label-size="sm"-->
<!--                        :class="'mb-1 '+ required"-->
<!--                    >-->
<!--                      <b-form-input-->
<!--                          id="first_pay_percent"-->
<!--                          size="sm"-->
<!--                          v-model="contract.first_pay_percent"-->
<!--                          type="number"-->
<!--                      />-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->
<!--                  <b-col md="6">-->
<!--                    <b-form-group-->
<!--                        label-cols="5"-->
<!--                        label-cols-lg="5"-->
<!--                        label="首款金额"-->
<!--                        label-for="first_pay_amount"-->
<!--                        label-size="sm"-->
<!--                        :class="'mb-1 '+ required"-->
<!--                    >-->
<!--                      <b-form-input-->
<!--                          id="first_pay_amount"-->
<!--                          size="sm"-->
<!--                          v-model="contract.first_pay_amount"-->
<!--                          type="number"-->
<!--                      />-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->

<!--                </b-row>-->
<!--              </b-col>-->

<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="首款付款条件"-->
<!--                    label-for="first_pay_condition"-->
<!--                    label-size="sm"-->
<!--                    :class="'mb-1 '+ required"-->
<!--                >-->
<!--                  <b-form-input-->
<!--                      id="first_pay_condition"-->
<!--                      size="sm"-->
<!--                      v-model="contract.first_pay_condition"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->
<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="首款预计付款时间"-->
<!--                    label-for="first_pay_paydate"-->
<!--                    label-size="sm"-->
<!--                    :class="'mb-1 '+ required"-->
<!--                >-->
<!--                  <flat-pickr-->
<!--                      v-model="contract.first_pay_paydate"-->
<!--                      class="form-control"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->
<!--              <b-col md="4">-->
<!--                <b-row>-->
<!--                  <b-col md="6">-->
<!--                    <b-form-group-->
<!--                        label-cols="5"-->
<!--                        label-cols-lg="5"-->
<!--                        label="中期款比例/%"-->
<!--                        label-for="middle_pay_percent"-->
<!--                        label-size="sm"-->
<!--                        :class="'mb-1 '+ required"-->
<!--                    >-->
<!--                      <b-form-input-->
<!--                          id="middle_pay_percent"-->
<!--                          size="sm"-->
<!--                          v-model="contract.middle_pay_percent"-->
<!--                          type="number"-->
<!--                      />-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->
<!--                  <b-col md="6">-->
<!--                    <b-form-group-->
<!--                        label-cols="5"-->
<!--                        label-cols-lg="5"-->
<!--                        label="中期款金额"-->
<!--                        label-for="middle_pay_amount"-->
<!--                        label-size="sm"-->
<!--                        :class="'mb-1 '+ required"-->
<!--                    >-->
<!--                      <b-form-input-->
<!--                          id="middle_pay_amount"-->
<!--                          size="sm"-->
<!--                          v-model="contract.middle_pay_amount"-->
<!--                          type="number"-->
<!--                      />-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->
<!--                </b-row>-->

<!--              </b-col>-->
<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="中期款付款条件"-->
<!--                    label-for="middle_pay_condition"-->
<!--                    label-size="sm"-->
<!--                    :class="'mb-1 '+ required"-->
<!--                >-->
<!--                  <b-form-input-->
<!--                      id="middle_pay_condition"-->
<!--                      size="sm"-->
<!--                      v-model="contract.middle_pay_condition"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->
<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="中期款预计付款时间"-->
<!--                    label-for="middle_pay_paydate"-->
<!--                    label-size="sm"-->
<!--                    :class="'mb-1 '+ required"-->
<!--                >-->
<!--                  <flat-pickr-->
<!--                      v-model="contract.middle_pay_paydate"-->
<!--                      class="form-control"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->
<!--              <b-col md="4">-->
<!--                <b-row>-->
<!--                  <b-col md="6">-->
<!--                    <b-form-group-->
<!--                        label-cols="5"-->
<!--                        label-cols-lg="5"-->
<!--                        label="尾款比例/%"-->
<!--                        label-for="last_pay_percent"-->
<!--                        label-size="sm"-->
<!--                        :class="'mb-1 '+ required"-->
<!--                    >-->
<!--                      <b-form-input-->
<!--                          id="last_pay_percent"-->
<!--                          size="sm"-->
<!--                          v-model="contract.last_pay_percent"-->
<!--                          type="number"-->
<!--                      />-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->
<!--                  <b-col md="6">-->
<!--                    <b-form-group-->
<!--                        label-cols="5"-->
<!--                        label-cols-lg="5"-->
<!--                        label="尾款金额"-->
<!--                        label-for="last_pay_amount"-->
<!--                        label-size="sm"-->
<!--                        :class="'mb-1 '+ required"-->
<!--                    >-->
<!--                      <b-form-input-->
<!--                          id="last_pay_amount"-->
<!--                          size="sm"-->
<!--                          v-model="contract.last_pay_amount"-->
<!--                          type="number"-->
<!--                      />-->
<!--                    </b-form-group>-->
<!--                  </b-col>-->
<!--                </b-row>-->

<!--              </b-col>-->

<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="尾款付款条件"-->
<!--                    label-for="last_pay_condition"-->
<!--                    label-size="sm"-->
<!--                    :class="'mb-1 '+ required"-->
<!--                >-->
<!--                  <b-form-input-->
<!--                      id="last_pay_condition"-->
<!--                      size="sm"-->
<!--                      v-model="contract.last_pay_condition"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->
<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="尾款预计付款时间"-->
<!--                    label-for="last_pay_paydate"-->
<!--                    label-size="sm"-->
<!--                    :class="'mb-1 '+ required"-->
<!--                >-->
<!--                  <flat-pickr-->
<!--                      v-model="contract.last_pay_paydate"-->
<!--                      class="form-control"-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="结算方式"
                    label-for="balancemethod"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="balancemethod"
                      size="sm"
                      v-model="contract.balancemethod"
                      placeholder="例如:分超过三个阶段付款的，请备注第二笔款、第三笔款的付款条款和时间要求。其他需要留意的亦可备注。"
                  />
                </b-form-group>
              </b-col>

              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    :label="type==1?'提货方式合同截图':'发货方式合同截图'"
                    label-for="attachments_pick_method"
                    label-size="sm"
                    class="mb-1"
                >
                  <attachment-upload v-if="contract.loaded" :theme="'files'"
                                     :attachment_id="'attachments_pick_method'"
                                     :id="contract.attachments_pick_method"
                                     :object_type="'contract'"
                                     :object_id="contract.contract_id"
                                     @change="onUploaded"
                  />
                </b-form-group>

              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="结算条款截图"
                    label-for="attachments_balance"
                    label-size="sm"
                    class="mb-1"
                >
                  <attachment-upload v-if="contract.loaded" :theme="'files'"
                                     :attachment_id="'attachments_balance'"
                                     :id="contract.attachments_balance"
                                     :object_type="'contract'"
                                     :object_id="contract.contract_id"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="1"
                    label-cols-lg="1"
                    label="发票及税率截图"
                    label-for="attachments_billfax"
                    label-size="sm"
                    class="mb-1"
                >
                  <attachment-upload v-if="contract.loaded" :theme="'files'"
                                     :attachment_id="'attachments_billfax'"
                                     :id="contract.attachments_billfax"
                                     :object_type="'contract'"
                                     :object_id="contract.contract_id"
                                     @change="onUploaded"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-card>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">决策评估</div>
        </b-col>

        <b-col md="12">
          <b-card header="决策评估">
            <b-row>
              <b-col md="4">
                <b-form-group
                        label-cols="3"
                        label-cols-lg="3"
                        label="产品类别"
                        label-for="category_name"
                        label-size="sm"
                        :class="'mb-1 '+ required"
                >

                  <v-select
                          id="category_id"
                          :options="category_Array"
                          :clearable="true"
                          v-model="categoryName"
                          @input="changeCategoryId($event)"
                          class="select-size-sm"
                  />
                </b-form-group>
              </b-col>
<!--              <b-col md="4">-->
<!--                <b-form-group-->
<!--                    label-cols="3"-->
<!--                    label-cols-lg="3"-->
<!--                    label="产品类别"-->
<!--                    label-for="category_name"-->
<!--                    label-size="sm"-->
<!--                    :class="'mb-1 '+ required"-->
<!--                >-->

<!--                  <b-form-input-->
<!--                      id="category_name"-->
<!--                      size="sm"-->
<!--                      v-model="categoryName"-->
<!--                      v-b-modal.modal-select-category-->
<!--                      placeholder="请选择产品类别"-->
<!--                      readonly=""-->
<!--                  />-->
<!--                </b-form-group>-->
<!--              </b-col>-->

<!--              <b-modal-->
<!--                  id="modal-select-category"-->
<!--                  ok-only-->
<!--                  ok-title="确认"-->
<!--                  @ok="onSelectCategory"-->
<!--                  cancel-title="取消"-->
<!--                  centered-->
<!--                  size="lg"-->
<!--                  title="选择类别"-->
<!--              >-->
<!--                <category-list-->
<!--                    ref="CategorySelects"-->
<!--                    v-on:table="fromChildren($event,'modal-select-category')"-->
<!--                >-->
<!--                </category-list>-->
<!--              </b-modal>-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="相关产品"
                    label-for="product_detail"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="product_detail"
                      size="sm"
                      v-model="contract.product_detail"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="供应商数量"
                    label-for="supplier_count"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="supplier_count"
                      size="sm"
                      v-model="contract.supplier_count"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="供方来源"
                    label-for="provide_source"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="provide_source"
                      :options="getCodeOptions('provide_source')"
                      :clearable="true"
                      v-model="provideSource"
                      @input="changeSelect('provide_source',$event)"
                      class="select-size-sm"
                      placeholder="请选择供方来源"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="供应商推荐人"
                    label-for="supplier_reference"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="supplier_reference"
                      size="sm"
                      v-model="contract.supplier_reference"
                  />
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="供应商引入理由"
                    label-for="supplier_reason"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="supplier_reason"
                      size="sm"
                      v-model="contract.supplier_reason"
                  />

                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="其他理由描述"
                    label-for="other_reason"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="other_reason"
                      size="sm"
                      v-model="contract.other_reason"
                  />

                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="供应商评级"
                    label-for="supplier_level"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <v-select
                      id="supplier_level"
                      :options="getCodeOptions('company_level')"
                      :clearable="true"
                      v-model="supplierLevel"
                      @input="changeSelect('supplier_level',$event)"
                      class="select-size-sm"
                      placeholder="请选择供应商评级"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-card>

        </b-col>

        <b-col md="12">
          <div class="inner-card-title">银行账户</div>
        </b-col>

        <b-col md="6">
          <b-card header="付款方信息">
            <b-row>
              <b-col md="12">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="付款人银行及支行名称"
                    label-for="pay_bank_name"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="pay_bank_name"
                      size="sm"
                      v-model="payBankName"
                      v-b-modal.modal-select-our_bank
                      readonly=""
                      placeholder="请选择付款人银行"
                  />
                </b-form-group>
              </b-col>
              <b-modal
                  id="modal-select-our_bank"
                  ok-only
                  ok-title="确认"
                  @ok="onSelectOurBank"
                  cancel-title="取消"
                  centered
                  size="lg"
                  title="选择银行"
              >
                <bank-account-list
                    ref="ourBankSelects" v-on:table="fromChildren($event,'modal-select-our_bank')"
                    :companyId="companyIdRelational"
                >
                </bank-account-list>
              </b-modal>
              <b-col md="12">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="付款人银行及支行地点"
                    label-for="pay_bank_address"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="pay_bank_address"
                      size="sm"
                      v-model="contract.pay_bank_address"
                      readonly=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="付款人开户名称"
                    label-for="pay_account_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="pay_account_name"
                      size="sm"
                      v-model="contract.pay_account_name"
                      readonly=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="付款人银行账号"
                    label-for="pay_account_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="pay_account_no"
                      size="sm"
                      v-model="contract.pay_account_no"
                      readonly=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="付款人是否境外"
                    label-for="pay_isoutlands"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      id="pay_isoutlands"
                      v-model="contract.pay_isoutlands"
                      value="1"
                      unchecked-value="0"
                      readonly=""
                      disabled
                  ></b-form-checkbox>

                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="6">
          <b-card header="收款方信息">
            <b-row>
              <b-col md="12">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款人银行及支行名称"
                    label-for="receive_bank_name"
                    label-size="sm"
                    :class="'mb-1 '+ required"
                >
                  <b-form-input
                      id="receive_bank_name"
                      size="sm"
                      v-model="payOtherBankName"
                      v-b-modal.modal-select-other_bank
                      readonly=""
                      placeholder="请选择收款人银行"
                  />
                </b-form-group>
              </b-col>
              <b-modal
                  id="modal-select-other_bank"
                  ok-only
                  ok-title="确认"
                  @ok="onSelectOtherBank"
                  cancel-title="取消"
                  centered
                  size="lg"
                  title="选择类别"
              >
                <bank-account-list
                    ref="otherBankSelects" v-on:table="fromChildren($event,'modal-select-other_bank')"
                    :companyId="companyIdOtherRelational"
                >
                </bank-account-list>
              </b-modal>
              <b-col md="12">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款人银行及支行地点"
                    label-for="receive_bank_address"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="receive_bank_address"
                      size="sm"
                      v-model="contract.receive_bank_address"
                      readonly=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款人开户名称"
                    label-for="receive_account_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="receive_account_name"
                      size="sm"
                      v-model="contract.receive_account_name"
                      readonly=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款人银行账号"
                    label-for="receive_account_no"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="receive_account_no"
                      size="sm"
                      v-model="contract.receive_account_no"
                      readonly=""
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="收款人是否境外"
                    label-for="receive_isoutlands"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-checkbox
                      name="check-button"
                      switch
                      inline
                      id="receive_isoutlands"
                      v-model="contract.receive_isoutlands"
                      value="1"
                      unchecked-value="0"
                      readonly=""
                      disabled
                  ></b-form-checkbox>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>

        <b-col md="12">
          <div class="inner-card-title">其他信息</div>
        </b-col>


        <b-col md="12" v-if="[2,4,5,6,8].includes(contract.status)">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="变更理由及影响"
              label-for="memo"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="memo"
                size="sm"
                v-model="contract.ext.oa_reason"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="备注"
              label-for="memo"
              label-size="sm"
              class="mb-1"
          >
            <b-form-textarea
                id="memo"
                size="sm"
                v-model="contract.memo"
                rows="3"
                max-rows="6"
            />
          </b-form-group>
        </b-col>
        <b-col md="12">
          <b-form-group
              label-cols="1"
              label-cols-lg="1"
              label="相关附件"
              label-for="attachments_ids"
              label-size="sm"
              class="mb-1"
          >
            <attachment-upload v-if="contract.loaded" :theme="'files'"
                               :attachment_id="'attachments_ids'"
                               :id="contract.attachments_ids"
                               :object_type="'contract'"
                               :object_id="contract.contract_id"
                               @change="onUploaded"
            />
          </b-form-group>
        </b-col>
        <!--        <b-col md="3">-->
        <!--          <b-form-group-->
        <!--              label-cols="3"-->
        <!--              label-cols-lg="3"-->
        <!--              label="审核状态  contract_status"-->
        <!--              label-for="status"-->
        <!--              label-size="sm"-->
        <!--              class="mb-1"-->
        <!--          >-->
        <!--            <v-select-->
        <!--                id="status"-->
        <!--                :options="getCodeOptions('contract_status')"-->
        <!--                :clearable="true"-->
        <!--                v-model="status"-->
        <!--                @input="changeSelect('status',$event)"-->
        <!--                class="select-size-sm"-->
        <!--            />-->
        <!--          </b-form-group>-->
        <!--        </b-col>-->

        <b-col
            cols="12"
            class="mt-50"
        >

          <b-button
              variant="primary"
              class="mr-1"
              @click="save"
              v-if="readonly === 0"
          >
            <span>保存</span>
          </b-button>

          <b-button
              variant="outline-secondary"
              @click="cancel"
          >
            取消
          </b-button>
        </b-col>
      </b-row>
    </b-form>
    <!--/ form -->
    </ValidationObserver>
  </b-card>
</template>

<script>
import {
  BCard,
  BMedia,
  BAvatar,
  BCardText,
  BForm,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BImg,
  BFormFile,
  BLink,
  BButton,
  BFormCheckbox,
  BCardBody,
  BTable,
  BFormDatepicker,
  VBModal,
} from 'bootstrap-vue'
import axios from '@axios'
import { VueAutosuggest } from 'vue-autosuggest'
import vSelect from 'vue-select'
import { computed, onUnmounted, ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import contractStore from './contractStore'
import userStore from './../user/userStore'
import companyStore from '../company/companyStore'
import bankaccountStore from "../bankaccount/bankaccountStore"
// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { getCode, getCodeColor, getCodeLabel, getCodeOptions, second, toDate, toTime } from '@core/utils/filter'
import ProductSelect from '@/views/apps/product/ProductSelect'
import Ripple from 'vue-ripple-directive'
import UserSelect from '@/views/apps/user/user-modal/UserSelect'
import productStore from '@/views/apps/product/productStore'
import contactStore from '@/views/apps/contact/contactStore'
import ContactSelect from '@/views/apps/contact/contact-modal/ContactSelect'
import flatPickr from 'vue-flatpickr-component'
import CategoryList from '@/views/apps/category/category-modal/CategoryList'
import BankAccountList from '@/views/apps/bankaccount/bankaccount-modal/BankAccountList'
import AttachmentUpload from '@/views/apps/attachment/AttachmentUpload'
import CompanySelect from '@/views/apps/company/CompanySelect'
import purchaseorderStore from '@/views/apps/purchaseorder/purchaseorderStore'
import categoryStore from "@/views/apps/category/categoryStore";


export default {
  components: {
    BankAccountList,
    CategoryList,
    ContactSelect,
    BCard,
    BMedia,
    BAvatar,
    BCardText,
    BForm,
    BLink,
    BImg,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormCheckbox,
    VueAutosuggest,
    vSelect,
    useToast,
    BCardBody,
    ProductSelect,
    directives: {
      'b-modal': VBModal,
      Ripple,
    },
    UserSelect,
    CompanySelect,
    BTable,
    BFormDatepicker,
    flatPickr,
    AttachmentUpload,
  },
  data() {
    return {
      dir: 'ltr',
      id: ref(0),
      contract: ref({}),
      company: ref({}),

      agreementBatch: {},
      sealType: {},
      provideSource: {},
      differentIdeaMethod: {},
      arbitrate: {},
      getgoods: [],
      agreementPayMethod: {},
      billType: {},
      taxRate: {},
      status: {},
      state: {},
      agreementType: {},
      moneyFlow: {},
      pickMethod: {},
      sourcefileType: {},

      checked: [],
      items: [],
      itemsOnUser: [],
      color: {},
      products: [],
      fullName: {},
      ourManageName: {},

      companys: [],
      ourCompanyName: "",
      ourCompanyId: {},
      otherCompanys: [],
      otherCompanyName: "",
      otherCompanyId: {},

      other_contact_name: {},
      otherContactName: {},
      itemsOnContact: [],
      category: {},

      pay: [],
      receive: [],
      username: '',
      categoryName: '',
      category_Array: [],
      categoryAll: [],
      supplierLevel: '',
      payBankName: '',
      payOtherBankName: '',
      type: 0,
      showOtherPick: false,
      otherPick: '',

      readonly:0,
      differentData:{},
      oldContract:{},
      companyIdRelational: 0,
      companyIdOtherRelational: 0,
      required:"required",
      isRequired:0,

      companyType:'',

      loadingOtherModal:false,
      loadingOurModal:false,
      userDepartment:"purchase",
    }
  },
  methods: {
    setOurCompanyName() {
      this.contract.our_company_name = this.ourCompanyName.label
      this.ourCompanyId = this.ourCompanyName.code
      this.contract.our_company_id = this.ourCompanyName.code
    },

    setOtherCompanyName() {
      this.contract.other_company_name = this.otherCompanyName.label
      this.otherCompanyId = this.otherCompanyName.code
      this.contract.other_company_id = this.otherCompanyName.code
    },

    setCategory() {
      this.contract.category_id = this.category.code
    },
    clearInfo(e) {
      this.companyIdRelational = e.code
      if (this.contract.our_manage_name != null) {
        this.contract.our_manage_name = null
      }
      if (this.contract.our_manage_tel != null) {
        this.contract.our_manage_tel = null
      }
      if (this.contract.our_manage_email != null) {
        this.contract.our_manage_email = null
      }
      if (this.payBankName != null) {
        this.payBankName = ''
      }
      if (this.contract.pay_bank_address != null) {
        this.contract.pay_bank_address = null
      }
      if (this.contract.pay_account_name != null) {
        this.contract.pay_account_name = null
      }
      if (this.contract.pay_account_no != null) {
        this.contract.pay_account_no = null
      }
      if (this.contract.pay_isoutlands != null) {
        this.contract.pay_isoutlands = null
      }

      this.contract.our_company_name = this.ourCompanyName.label
      this.ourCompanyId = this.ourCompanyName.code
      this.contract.our_company_id = this.ourCompanyName.code
    },
    clearInfoOtherCompany(e) {
      this.companyIdOtherRelational = e.code
      if (this.contract.other_contact_name != null) {
        this.contract.other_contact_name = null
      }
      if (this.contract.other_company_id != null) {
        this.contract.other_company_id = null
      }
      if (this.contract.other_contact_tel != null) {
        this.contract.other_contact_tel = null
      }
      if (this.payOtherBankName != null) {
        this.payOtherBankName = ''
      }
      if (this.contract.receive_bank_address != null) {
        this.contract.receive_bank_address = null
      }
      if (this.contract.receive_account_name != null) {
        this.contract.receive_account_name = null
      }
      if (this.contract.receive_account_no != null) {
        this.contract.receive_account_no = null
      }
      if (this.contract.receive_isoutlands != null) {
        this.contract.receive_isoutlands = null
      }
    }
  },
  setup() {
    const toast = useToast()

    // Register module
    if (!store.hasModule('contract')) store.registerModule('contract', contractStore)
    if (!store.hasModule('purchaseorder')) store.registerModule('purchaseorder', purchaseorderStore)
    if (!store.hasModule('user')) store.registerModule('user', userStore)
    if (!store.hasModule('contact')) store.registerModule('contact', contactStore)
    if (!store.hasModule('company')) store.registerModule('company', companyStore)
    if (!store.hasModule('bankaccount')) store.registerModule('bankaccount', bankaccountStore)
    if (!store.hasModule('category')) store.registerModule('category', categoryStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('contract')) store.unregisterModule('contract')
      if (store.hasModule('user')) store.unregisterModule('user')
      if (store.hasModule('contact')) store.unregisterModule('contact')
      if (store.hasModule('company')) store.unregisterModule('company')
      if (store.hasModule('bankaccount')) store.unregisterModule('bankaccount')
      if (store.hasModule('purchaseorder')) store.unregisterModule('purchaseorder')
      if (store.hasModule('category')) store.unregisterModule('category')
    })

    const edit = function () {
      store.dispatch('category/select',{disable:true}).then(res => { //获得一级分类下拉数据
        this.categoryAll = res.data.data;
        for (let i = 0; i < this.categoryAll.length ; i++) {
          //初始化一级category
          if(this.categoryAll[i].level === 1){
            this.category_Array.push({
              "label": this.categoryAll[i].name,
              "category_id": this.categoryAll[i].category_id,
            });
          }
        }
      })


      store.dispatch('contract/edit', {
        id: this.id,
        type: this.type,
        readonly:this.readonly
      })
          .then(res => {
            if (res.data.code==1){
              toast.error(res.data.data)
              if (this.type == 1) {
                this.$router.push({
                  name: 'apps-contract-purchase-list',
                  query: { type: 1 }
                })
              }
              if (this.type == 2) {
                this.$router.push({
                  name: 'apps-contract-sales-list',
                  query: { type: 2 }
                })
              }
            }
            this.contract = res.data.data
            this.contract.applier_date = toDate(this.contract.applier_date)
            this.agreementBatch = getCode('agreement_batch', this.contract.agreement_batch)
            this.sealType = getCode('seal_type', this.contract.seal_type)
            this.provideSource = getCode('provide_source', this.contract.provide_source)
            this.differentIdeaMethod = getCode('different_idea_method', this.contract.different_idea_method)
            this.arbitrate = getCode('arbitrate_locale', this.contract.arbitrate)
            getGetgoods(this.contract.getgoods, this.getgoods, this.showOtherPick)
            this.agreementPayMethod = getCode('agreement_pay_method', this.contract.agreement_pay_method)
            this.billType = getCode('invoice_type', this.contract.bill_type)
            this.taxRate = getCode('tax_rate', this.contract.tax_rate)
            this.status = getCode('contract_status', this.contract.status)
            this.agreementType = getCode('agreement_specific_type_CG', this.contract.agreement_type)
            this.moneyFlow = getCode('supplier_money_flow_CG', this.contract.money_flow)
            this.pickMethod = getCode('pickup_method_CG', this.contract.pick_method)
            this.sourcefileType = getCode('sourcefile_type', this.contract.sourcefile_type)
            this.supplierLevel = getCode('company_level', this.contract.supplier_level)
            if (this.contract.last_pay_paydate!=undefined){
              this.contract.last_pay_paydate = toDate(this.contract.last_pay_paydate)
            }
            if (this.contract.first_pay_paydate!=undefined){
              this.contract.first_pay_paydate = toDate(this.contract.first_pay_paydate)
            }
            if (this.contract.middle_pay_paydate!=undefined){
              this.contract.middle_pay_paydate= toDate(this.contract.middle_pay_paydate)
            }
            this.ourCompanyName = this.contract.our_company_name
            this.ourCompanyId = this.contract.our_company_id

            this.companyIdRelational = this.contract.our_company_id
            this.companyIdOtherRelational = this.contract.other_company_id

            this.otherCompanyName = this.contract.other_company_name
            this.otherCompanyId = this.contract.other_company_id
            if (this.contract.agreement_start!=undefined){
              this.contract.agreement_start = toDate(this.contract.agreement_start)
            }
            if (this.contract.agreement_end!=null){
              this.contract.agreement_end = toDate(this.contract.agreement_end)
            }
            this.category = this.contract.category_id
            this.contract.reference_law = '中国'
            this.contract.balance_currency = '人民币'
            this.payBankName = this.contract.pay_bank_name
            this.payOtherBankName = this.contract.receive_bank_name
            this.username = this.contract.applier_name
            this.categoryName = this.contract.category_name
            getMyCompany(this.companys, 2)
            if (this.type == 1) {
              getMyCompany(this.otherCompanys, 1)
            }
            if (this.type == 2) {
              getMyCompany(this.otherCompanys, 3)
            }

            //
            this.contract.actual_card = this.contract.ext.company.actual_card;
            this.contract.actual_controller = this.contract.ext.company.actual_controller;
            this.contract.actual_phone = this.contract.ext.company.actual_phone;

          })
    }

    const getGetgoods = function (params, val, flag) {
      if (params != undefined) {
        if (params.length > 1) {
          let array = (params).split(',')
          for (let i = 0; i < array.length; i++) {
            if (array[i] == 4) {
              flag = true
            }
            val.push(getCode('getgoods', array[i]))
          }
        } else {
          val.push(getCode('getgoods', params))
        }
      }
    }

    const onSelectOtherName = function () {
      let list = this.$refs.CompanySelect.getSelected();
      if(list.length > 0){
        let checked = list[0];
        this.contract.actual_card = checked.actual_card;
        this.contract.actual_controller = checked.actual_controller;
        this.contract.actual_phone = checked.actual_phone;


        this.contract.other_company_id = checked.company_id;
        this.contract.other_company_name = checked.company_name;
        this.otherCompanyName = checked.company_name;
        this.otherCompanyId = checked.company_id;
        this.companyIdOtherRelational = checked.company_id;
        axios
                .post('/api/bankaccount/search', {
                  company_id: this.companyIdOtherRelational
                }).then(res => {
          const data = res.data.data.ext.list;
          this.contract.receive_account_type = ''
          this.contract.receive_isoutlands = ''
          this.contract.receive_account_name = ''
          this.contract.receive_account_no = ''
          this.contract.receive_bank_name = ''
          this.contract.receive_bank_address = ''
          this.payOtherBankName = ''
          if (data.length === 1) {
            this.contract.receive_account_type = data[0].account_type
            this.contract.receive_isoutlands = data[0].is_outlands
            this.contract.receive_account_name = data[0].account_name
            this.contract.receive_account_no = data[0].account_no
            this.contract.receive_bank_name = data[0].bank_name
            this.contract.receive_bank_address = data[0].bank_node_address
            this.payOtherBankName = data[0].bank_name
          }
        })
      }
      this.loadingOtherModal = false;

    }

    const onSelectOurName = function () {
      let list = this.$refs.CompanySelect.getSelected();
      if(list.length > 0){
        let checked = list[0];
        this.contract.our_company_id = checked.company_id;
        this.contract.our_company_name = checked.company_name;
        this.ourCompanyName = checked.company_name;
        this.ourCompanyId = checked.company_id;
        this.companyIdRelational = checked.company_id;

        axios
                .post('/api/bankaccount/search', {
                  company_id: this.companyIdRelational
                }).then(res => {
          this.contract.pay_account_type =''
          this.contract.pay_isoutlands=''
          this.contract.pay_account_name = ''
          this.contract.pay_account_no = ''
          this.contract.pay_bank_name = ''
          this.contract.pay_bank_address = ''
          this.payBankName = ''
          const data = res.data.data.ext.list;
          if (data.length === 1) {
            this.contract.pay_account_type = data[0].account_type
            this.contract.pay_isoutlands = data[0].is_outlands
            this.contract.pay_account_name = data[0].account_name
            this.contract.pay_account_no = data[0].account_no
            this.contract.pay_bank_name = data[0].bank_name
            this.contract.pay_bank_address = data[0].bank_node_address
            this.payBankName = data[0].bank_name
          }
        })
      }
      this.loadingOurModal = false;
    }


    const view = function () {
      store.dispatch('contract/view', { id: this.id })
          .then(res => {
            this.contract = res.data.data
          })
    }

    const cancel = function () {
      this.$router.go(-1)
    }

    const closeOtherModal = function(){
      this.loadingOtherModal = false;
    }

    const closeOurModal = function () {
      this.loadingOurModal = false;
    }

    const changeOtherCompanyName = function () {
      this.loadingOtherModal = true;
      this.companyType = "Supplier";
    }

    const changeOurCompanyName = function () {
      this.loadingOurModal = true;
      this.companyType = "Group";
    }
    const save = async function () {
      if (this.contract.status === 4){
        toast.error('当前状态不能保存')
        return false
      }
      if (this.contract.actual_controller === undefined || this.contract.actual_controller === "") {
        toast.error("请填写 企业实控人!")
        return false
      }
      if (this.contract.actual_card === undefined || this.contract.company_name === "") {
        toast.error("请填写 实控人身份证号!")
        return false
      }
      if (this.contract.actual_phone === undefined || this.contract.company_name === "") {
        toast.error("请填写 实控人手机号!")
        return false
      }
      if (this.required!=""){
        if (this.contract.contract_title === '' || this.contract.contract_title === undefined) {
          toast.error('请填写 标题！')
          return false
        }
        if (this.contract.applier_name === '' || this.contract.applier_name === undefined) {
          toast.error('请填写 申请人！')
          return false
        }
        if(this.contract.agreement_type === '' || this.contract.agreement_type === undefined){
          toast.error('请填写 合同类型！')
          return false
        }
        if(this.contract.agreement_batch === '' || this.contract.agreement_batch === undefined){
          toast.error('请填写 合同版本！')
          return false
        }
        if (this.contract.agreement_name === '' || this.contract.agreement_name === undefined) {
          toast.error('请填写 合同名称！')
          return false
        }
        if (this.contract.seal_type === '' || this.contract.seal_type === undefined) {
          toast.error('请填写 公章类型！')
          return false
        }
        if (this.contract.agreement_count === '' || this.contract.agreement_count === undefined) {
          toast.error('请填写 合同份数！')
          return false
        }
        if (this.contract.different_idea_method === '' || this.contract.different_idea_method === undefined) {
          toast.error('请填写 争议解决方式！')
          return false
        }
        if (this.contract.arbitrate === '' || this.contract.arbitrate === undefined) {
          toast.error('请填写 仲裁地点！')
          return false
        }
        if (this.contract.our_company_name === '' || this.contract.our_company_name === undefined) {
          toast.error('请填写 我方单位名称！')
          return false
        }
        if (this.contract.our_manage_name === '' || this.contract.our_manage_name === undefined) {
          toast.error('请填写 我方负责人姓名！')
          return false
        }
        if (this.contract.our_manage_email === '' || this.contract.our_manage_email === undefined) {
          toast.error('请填写 我方授权电子邮箱！')
          return false
        }
        if (this.contract.other_company_name === '' || this.contract.other_company_name === undefined) {
          toast.error('请填写 对方单位名称！')
          return false
        }
        if (this.contract.other_contact_name === '' || this.contract.other_contact_name === undefined) {
          toast.error('请填写 对方负责人名称！')
          return false
        }
        if (this.contract.other_contact_email === '' || this.contract.other_contact_email === undefined) {
          toast.error('请填写 对方授权电子邮箱！')
          return false
        }
        if (this.contract.getgoods === '' || this.contract.getgoods === undefined) {
          toast.error('请填写 交货地点！')
          return false
        }
        if (this.contract.money_flow === '' || this.contract.money_flow === undefined) {
          toast.error('请填写 资金流向！')
          return false
        }
        if (this.contract.pick_method === '' || this.contract.pick_method === undefined) {
          toast.error('请填写 发货方式！')
          return false
        }
        if (this.contract.pick_method_page === '' || this.contract.pick_method_page === undefined) {
          toast.error('请填写 发货方式合同页码！')
          return false
        }
        if (this.contract.agreement_pay_method === '' || this.contract.agreement_pay_method === undefined) {
          toast.error('请填写 付款方式！')
          return false
        }
        if (this.contract.balancemethod === '' || this.contract.balancemethod === undefined) {
          toast.error('请填写 结算方式！')
          return false
        }
        if (this.contract.balance_page === '' || this.contract.balance_page === undefined) {
          toast.error('请填写 结算条款合同页码！')
          return false
        }
        if (this.contract.bill_type === '' || this.contract.bill_type === undefined) {
          toast.error('请填写 我方开立发票类型！')
          return false
        }
        if (this.contract.tax_rate === '' || this.contract.tax_rate === undefined) {
          toast.error('请填写 税率！')
          return false
        }
        if (this.contract.bill_fax_page === '' || this.contract.bill_fax_page === undefined) {
          toast.error('请填写 发票和税率合同页码！')
          return false
        }
        if (this.contract.category_name === '' || this.contract.category_name === undefined) {
          toast.error('请填写 产品类别！')
          return false
        }
        if (this.contract.pay_bank_name === '' || this.contract.pay_bank_name === undefined) {
          toast.error('请填写 付款人银行及支行名称！')
          return false
        }



        if (this.contract.agreement_start === '' || this.contract.agreement_start === undefined) {
          toast.error('请填写 合同开始时间！')
          return false
        }
        if (this.contract.agreement_end === '' || this.contract.agreement_end === undefined) {
          toast.error('请填写 合同结束时间！')
          return false
        }
        if (this.contract.deposit === '' || this.contract.deposit === undefined) {
          toast.error('请填写 保证金！')
          return false
        }
        if (this.contract.paymentdays === '' || this.contract.paymentdays === undefined) {
          toast.error('请填写 账期天数！')
          return false
        }
        if (this.contract.payment_info === '' || this.contract.payment_info === undefined) {
          toast.error('请填写 账期描述！')
          return false
        }
        if (this.contract.break_promise_percent === '' || this.contract.break_promise_percent === undefined) {
          toast.error('请填写 逾期违约金比率(%/日)！')
          return false
        }
        // if (this.contract.prepay === '' || this.contract.prepay === undefined) {
        //   toast.error('请填写 预付款！')
        //   return false
        // }
        if (this.contract.supplier_reason === '' || this.contract.supplier_reason === undefined) {
          toast.error('请填写 供应商引入理由！')
          return false
        }
        if (this.contract.supplier_level === '' || this.contract.supplier_level === undefined) {
          toast.error('请填写 供应商评级！')
          return false
        }
        let validate = await this.$refs.observer.validate()
        if (!validate){
          toast.error('请正确填写必填字段！')
          return false
        }
        //
        // if (this.contract.first_pay_percent === '' || this.contract.first_pay_percent === undefined) {
        //   toast.error('请填写 首款比例/%！')
        //   return false
        // }
        // if (this.contract.first_pay_amount === '' || this.contract.first_pay_amount === undefined) {
        //   toast.error('请填写 首款金额！')
        //   return false
        // }
        // if (this.contract.first_pay_condition === '' || this.contract.first_pay_condition === undefined) {
        //   toast.error('请填写 首款付款条件！')
        //   return false
        // }
        // if (this.contract.first_pay_paydate === '' || this.contract.first_pay_paydate === undefined) {
        //   toast.error('请填写 首款预计付款时间！')
        //   return false
        // }
        // if (this.contract.middle_pay_percent === '' || this.contract.middle_pay_percent === undefined) {
        //   toast.error('请填写 中期款比例/%！')
        //   return false
        // }
        // if (this.contract.middle_pay_amount === '' || this.contract.middle_pay_amount === undefined) {
        //   toast.error('请填写 中期款金额！')
        //   return false
        // }
        // if (this.contract.middle_pay_condition === '' || this.contract.middle_pay_condition === undefined) {
        //   toast.error('请填写 中期款付款条件！')
        //   return false
        // }
        // if (this.contract.middle_pay_paydate === '' || this.contract.middle_pay_paydate === undefined) {
        //   toast.error('请填写 中期款预计付款时间！')
        //   return false
        // }
        // if (this.contract.last_pay_percent === '' || this.contract.last_pay_percent === undefined) {
        //   toast.error('请填写 尾款比例/%！')
        //   return false
        // }
        // if (this.contract.last_pay_amount === '' || this.contract.last_pay_amount === undefined) {
        //   toast.error('请填写 尾款金额！')
        //   return false
        // }
        // if (this.contract.last_pay_condition === '' || this.contract.last_pay_condition === undefined) {
        //   toast.error('请填写 尾款付款条件！')
        //   return false
        // }
        // if (this.contract.last_pay_paydate === '' || this.contract.last_pay_paydate === undefined) {
        //   toast.error('请填写 尾款预计付款时间！')
        //   return false
        // }
       /* if (this.contract.attachments_sourcefile === '' || this.contract.attachments_sourcefile === undefined) {
          toast.error('请填写 合同扫描件！')
          return false
        }*/

      }


      this.contract.applier_date = second(this.contract.applier_date)
      this.contract.agreement_start = second(this.contract.agreement_start)
      this.contract.agreement_end = second(this.contract.agreement_end)
      this.contract.first_pay_paydate = second(this.contract.first_pay_paydate)
      this.contract.middle_pay_paydate = second(this.contract.middle_pay_paydate)
      this.contract.last_pay_paydate = second(this.contract.last_pay_paydate)
      this.contract['oa_reason'] = this.contract.ext['oa_reason']
      store.dispatch('contract/save', this.contract)
          .then(res => {
            if (res.data.code==0){
              toast.success('数据已保存!')
              this.$router.go(-1)
              /*if (this.type == 1) {
                this.$router.push({
                  name: 'apps-contract-purchase-list',
                  query: { type: 1 }
                })
              }
              if (this.type == 2) {
                this.$router.push({
                  name: 'apps-contract-sales-list',
                  query: { type: 2 }
                })
              }*/
            }else {
              toast.error("保存失败!")
            }

          })
    }

    const changeSelect = function (key, event) {
      this.contract[key] = event.value
      if (key === 'getgoods') {
        let getGoodsArray = new Array()
        for (let i = 0; i < event.length; i++) {
          getGoodsArray.push(event[i].value)
          // if (getGoodsArray.join(",").search("4")!=-1){
          //   this.showOtherPick=true
          // }else {
          //   this.showOtherPick=false
          //   this.contract.getgoods_other=""
          // }
        }
        this.contract.getgoods = getGoodsArray.join(',')
      }
    }


    const onSelectProducts = function (bvModalEvt) {
      const checked = this.$refs.myUserSelect.getSelected()[0]
      this.contract.applier_name = checked.full_name
      this.username = checked.full_name
      this.contract.applier_id = checked.user_id
      this.contract.applier_department_id = checked.deptartment_id
      this.contract.applier_department_name = getCodeLabel('department', checked.deptartment_id)
      this.contract.applier_company_id = checked.company_id
      this.contract.applier_company_name = getCodeLabel('company', checked.company_id)
      this.contract.applier_job_num = checked.job_num
    }

    const onSelectProductsOnUser = function (bvModalEvt) {
      let checked = this.$refs.myUserSelects.getSelected()
      this.itemsOnUser = this.itemsOnUser.concat(checked)
      this.ourManageName = this.itemsOnUser[0].full_name
      this.contract.our_manage_name = this.ourManageName
      this.contract.our_manage_id = this.itemsOnUser[0].user_id
      this.contract.our_manage_tel = this.itemsOnUser[0].mobile
      this.contract.our_manage_email = this.itemsOnUser[0].email
      this.itemsOnUser.length = 0
    }

    const onSelectContact = function (bvModalEvt) {
      let checked = this.$refs.myContactSelects.getSelected()
      this.itemsOnContact = this.itemsOnContact.concat(checked)
      this.otherContactName = this.itemsOnContact[0].name
      this.contract.other_contact_name = this.otherContactName
      this.contract.other_contact_id = this.itemsOnContact[0].contact_id
      this.contract.other_contact_tel = this.itemsOnContact[0].mobile
      this.contract.other_contact_email = this.itemsOnContact[0].email
      this.itemsOnContact.length = 0
    }

    const onSelectCategory = function (bvModalEvt) {
      let checked = this.$refs.CategorySelects.getSelected()[0]
      this.contract.category_id = checked.category_id
      this.contract.category_name = checked.name
      this.categoryName = checked.name
    }

    const onSelectOurBank = function (bvModalEvt) {
      let list = this.$refs.ourBankSelects.getSelected();
      if(list.length > 0){
        let checked = list[0];
        this.contract.pay_account_type = checked.account_type
        this.contract.pay_isoutlands = checked.is_outlands
        this.contract.pay_account_name = checked.account_name
        this.contract.pay_account_no = checked.account_no
        this.contract.pay_bank_name = checked.bank_name
        this.contract.pay_bank_address = checked.bank_node_address
        this.payBankName = checked.bank_name
      }
    }

    const onSelectOtherBank = function (bvModalEvt) {
      let list = this.$refs.otherBankSelects.getSelected();
      if(list.length > 0){
        let checked = list[0];
        this.contract.receive_account_type = checked.account_type
        this.contract.receive_isoutlands = checked.is_outlands
        this.contract.receive_account_name = checked.account_name
        this.contract.receive_account_no = checked.account_no
        this.contract.receive_bank_name = checked.bank_name
        this.contract.receive_bank_address = checked.bank_node_address
        this.payOtherBankName = checked.bank_name
      }
    }

    const getMyCompany = function (params, type) {
      params.length = 0
      switch (type) {
        case 2: {
          store.dispatch('company/searchGroup')
              .then(res => {
                const data = res.data.data.list
                for (let i = 0; i < data.length; i++) {
                  params.push({
                    'label': data[i].company_name,
                    'code': data[i].company_id
                  })
                }
              })
          break
        }
        case 3: {
          store.dispatch('company/searchSupplier')
              .then(res => {
                const data = res.data.data.list
                for (let i = 0; i < data.length; i++) {
                  params.push({
                    'label': data[i].company_name,
                    'code': data[i].company_id
                  })
                }
              })
          break
        }
        case 1: {
          store.dispatch('company/searchChannelCustomer')
              .then(res => {
                const data = res.data.data.list
                for (let i = 0; i < data.length; i++) {
                  params.push({
                    'label': data[i].company_name,
                    'code': data[i].company_id
                  })
                }
              })
          break
        }
      }
    }

    const onUploaded = function (id, attachment, result) {
      this.contract[id] = result
    }

    const fromChildren = function (checked,modal) {
      if (modal=='modal-select-user'){
        this.contract.applier_name = checked.full_name
        this.username = checked.full_name
        this.contract.applier_id = checked.user_id
        this.contract.applier_department_id = checked.deptartment_id
        this.contract.applier_department_name = getCodeLabel('department', checked.deptartment_id)
        this.contract.applier_company_id = checked.company_id
        this.contract.applier_company_name = getCodeLabel('company', checked.company_id)
        this.contract.applier_job_num = checked.job_num
      }
      if (modal=='modal-select-users'){
        this.itemsOnUser = this.itemsOnUser.concat(checked)
        this.ourManageName = this.itemsOnUser[0].full_name
        this.contract.our_manage_name = this.ourManageName
        this.contract.our_manage_id = this.itemsOnUser[0].user_id
        this.contract.our_manage_tel = this.itemsOnUser[0].mobile
        this.contract.our_manage_email = this.itemsOnUser[0].email
        this.itemsOnUser.length = 0
      }
      if (modal=='modal-select-contact'){
        this.itemsOnContact = this.itemsOnContact.concat(checked)
        this.otherContactName = this.itemsOnContact[0].name
        this.contract.other_contact_name = this.otherContactName
        this.contract.other_contact_id = this.itemsOnContact[0].contact_id
        this.contract.other_contact_tel = this.itemsOnContact[0].mobile
        this.contract.other_contact_email = this.itemsOnContact[0].email
        this.itemsOnContact.length = 0
      }
      if (modal=='modal-select-category'){
        this.contract.category_id = checked.category_id
        this.contract.category_name = checked.name
        this.categoryName = checked.name
      }
      if (modal=='modal-select-our_bank'){
        this.contract.pay_account_type = checked.account_type
        this.contract.pay_isoutlands = checked.is_outlands
        this.contract.pay_account_name = checked.account_name
        this.contract.pay_account_no = checked.account_no
        this.contract.pay_bank_name = checked.bank_name
        this.contract.pay_bank_address = checked.bank_node_address
        this.payBankName = checked.bank_name
      }
      if (modal=='modal-select-other_bank'){
        this.contract.receive_account_type = checked.account_type
        this.contract.receive_isoutlands = checked.is_outlands
        this.contract.receive_account_name = checked.account_name
        this.contract.receive_account_no = checked.account_no
        this.contract.receive_bank_name = checked.bank_name
        this.contract.receive_bank_address = checked.bank_node_address
        this.payOtherBankName = checked.bank_name
      }
      if (modal=="modal-select-our"){
          this.contract.our_company_id = checked.company_id;
          this.contract.our_company_name = checked.company_name;
          this.ourCompanyName = checked.company_name;
          this.ourCompanyId = checked.company_id;
          this.companyIdRelational = checked.company_id;
          axios
              .post('/api/bankaccount/search', {
                company_id: this.companyIdRelational
              }).then(res => {
            this.contract.pay_account_type =''
            this.contract.pay_isoutlands=''
            this.contract.pay_account_name = ''
            this.contract.pay_account_no = ''
            this.contract.pay_bank_name = ''
            this.contract.pay_bank_address = ''
            this.payBankName = ''
            const data = res.data.data.ext.list;
            if (data.length === 1) {
              this.contract.pay_account_type = data[0].account_type
              this.contract.pay_isoutlands = data[0].is_outlands
              this.contract.pay_account_name = data[0].account_name
              this.contract.pay_account_no = data[0].account_no
              this.contract.pay_bank_name = data[0].bank_name
              this.contract.pay_bank_address = data[0].bank_node_address
              this.payBankName = data[0].bank_name
            }
          })
        this.loadingOurModal = false;
      }
      if (modal=="modal-select-other"){
          this.contract.other_company_id = checked.company_id;
          this.contract.other_company_name = checked.company_name;
          this.otherCompanyName = checked.company_name;
          this.otherCompanyId = checked.company_id;
          this.companyIdOtherRelational = checked.company_id;
          axios
              .post('/api/bankaccount/search', {
                company_id: this.companyIdOtherRelational
              }).then(res => {
            const data = res.data.data.ext.list;
            this.contract.receive_account_type = ''
            this.contract.receive_isoutlands = ''
            this.contract.receive_account_name = ''
            this.contract.receive_account_no = ''
            this.contract.receive_bank_name = ''
            this.contract.receive_bank_address = ''
            this.payOtherBankName = ''
            if (data.length === 1) {
              this.contract.receive_account_type = data[0].account_type
              this.contract.receive_isoutlands = data[0].is_outlands
              this.contract.receive_account_name = data[0].account_name
              this.contract.receive_account_no = data[0].account_no
              this.contract.receive_bank_name = data[0].bank_name
              this.contract.receive_bank_address = data[0].bank_node_address
              this.payOtherBankName = data[0].bank_name
            }
          })
        this.loadingOtherModal = false;
      }
      this.$bvModal.hide(modal)
    }

    const isVMI = function (event) {
      if (event==1){
        this.required=""
      }else {
        this.required="required"
      }
    }

    const changeCategoryId = function (event) {

      if(this.categoryName ===null || this.categoryName === ''){
        this.contract.category_id  = 0;
        this.contract.category_name  ='';
        this.categoryName = '';
      }else{
        this.contract.category_id  = event.category_id;
        this.contract.category_name  =event.label;
        this.categoryName = event.label;
      }
    }

    return {
      edit,
      view,
      cancel,
      save,
      closeOtherModal,
      closeOurModal,
      changeOtherCompanyName,
      changeOurCompanyName,
      onSelectOtherName,
      onSelectOurName,

      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,

      changeSelect,
      toDate,
      toTime,
      second,

      onSelectProducts,
      onSelectProductsOnUser,
      onSelectContact,
      onSelectCategory,

      getMyCompany,
      onSelectOurBank,
      onSelectOtherBank,
      onUploaded,
      getGetgoods,
      fromChildren,
      isVMI,
      changeCategoryId,
      departmentId:computed(()=>store.getters['purchaseorder/getDepartmentId']),
    }
  },
  watch: {
    $route(to, from) {
      this.type = this.$route.query.type
      // if (this.type===1){
      //   alert("这是采购协议")
      // }
      // if (this.type===2){
      //   alert("这是销售协议")
      // }
      if (this.type === 1) {
        this.getMyCompany(this.otherCompanys, 3)
      }
      if (this.type === 2) {
        this.getMyCompany(this.otherCompanys, 1)
      }
      for (let key in this.contract) {
        delete this.contract[key]
      }
      this.edit()
    }
  },
  created() {
    this.id = this.$route.query.id || 0
    this.type = this.$route.query.type
    if (this.type==""){
      this.$router.push({
        name: 'apps-contract-purchase-list',
        query: { type: 1 }
      })
    }
    this.readonly = this.$route.params.readonly || null
    if (this.$route.params.readonly===0||this.id===0){
      this.readonly=0
    }

    this.edit()
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
